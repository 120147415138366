import React, {useEffect, useState} from "react";
import CatalogProductList from "../CatalogProductsList/CatalogProductsList";
import useTranslations from "../../components/useTranslations";

const Pagination = ({ posts }) => {

	const { pages } = useTranslations();
	// if (typeof window !== `undefined`) {
	// 	window.history.pushState(null, null, null);
	// 	window.addEventListener('popstate', (e) => {
	// 		paginate(currentPage);
	// 		{window.scrollTo(0, 0)}
	// 	});
	// }
	useEffect(() => {
		return () => {
			window.history.pushState(null, null, window.location.href);
			window.addEventListener('popstate', (e) => {
				// paginate(currentPage);
				window.scrollTo(0, 0);
			});
		}}
	)

	const [currentPage, setCurrentPage] = useState(1);
	const [currentPageSp, setCurrentPageSp] = useState(1);
	const postsPerPage = 40;
	const postsPerPageSp = 10;
	const totalPage = Math.ceil(posts.length / postsPerPage);
	const totalPageSp = Math.ceil(posts.length / postsPerPageSp);

	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber)
		window.scrollTo(0, 0);
	};
	const paginateSp = (pageNumber) => setCurrentPageSp(pageNumber);

	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

	const indexOfLastPostSp = currentPageSp * postsPerPageSp;
	const indexOfFirstPostSp = indexOfLastPostSp - postsPerPageSp;
	const currentPostsSp = posts.slice(indexOfFirstPostSp, indexOfLastPostSp);

	return (
		<>
			<div className={"pc"}>
				<CatalogProductList posts={currentPosts} />
				<div className="pagination-bar flex center">
					<input
						type="button"
						onClick={() =>
							paginate(currentPage > 1 ? currentPage - 1 : currentPage)
						}
						className="prev"
						name="prev"
					/>
					<div className="flex-part flex center">
						<p>{pages}：</p>
						<input
							className={"medium page-number"}
							type={"text"}
							pattern="[0-9]*"
							onInput={(e) => paginate(parseInt(e.target.value), 10)}
							value={currentPage}
						/>
						<p>/ {totalPage}</p>
					</div>
					<input
						type="button"
						onClick={() =>
							paginate(currentPage < totalPage ? currentPage + 1 : currentPage)
						}
						className="next"
						name="next"
					/>
				</div>
			</div>
			<div className={"sp"}>
				<CatalogProductList posts={currentPostsSp} />
				<div className="pagination-bar flex center">
					<input
						type="button"
						onClick={() =>
							paginateSp(currentPageSp > 1 ? currentPageSp - 1 : currentPageSp)
						}
						className="prev"
						name="prev"
					/>
					<div className="flex-part flex center">
						<p>{pages}：</p>
						<input
							className={"medium page-number"}
							type={"text"}
							pattern="[0-9]*"
							onInput={(e) => paginateSp(e.target.value)}
							value={currentPageSp}
						/>
						<p>/ {totalPageSp}</p>
					</div>
					<input
						type="button"
						onClick={() =>
							paginateSp(
								currentPageSp < totalPageSp ? currentPageSp + 1 : currentPageSp
							)
						}
						className="next"
						name="next"
					/>
				</div>
			</div>
		</>
	);
};

export default Pagination;
