import React from "react";
import useTranslations from "../../components/useTranslations";

const CatalogProductList = ({ posts }) => {
	const { yen, taxIncluded } = useTranslations();
	const pathName = typeof window !== 'undefined' ? window.location.pathname : '';
	return (
		<div>
			{
				(pathName.indexOf('/en') !== -1) ?
					<ul className="product-list flex wrap">
						{posts.map(({ node: post }) => (
							<a
								key={`${post.frontmatter.sku}-${post.fields.locale}`}
								className={`product-link`}
								href={`/en/${post.parent.relativeDirectory}`}
								target={'_blank'}
								rel="noreferrer"
							>
								<li>
									<div className="flex column-to-row">
										<img
											src={post.frontmatter.productImages[0]}
											width={"281px"}
											height={"211px"}
											alt={post.frontmatter.name}
										/>
										<div className="txt-box">
											<p className="sku">{post.frontmatter.sku}</p>
											<p className="name medium bold">{post.frontmatter.name}</p>
											<p className="name medium bold">{post.frontmatter.model}</p>
										</div>
									</div>
								</li>
							</a>
						))}
					</ul>
					:
					<ul className="product-list flex wrap">
						{posts.map(({ node: post }) => (
							<a
								key={`${post.frontmatter.sku}-${post.fields.locale}`}
								className={`product-link`}
								href={`/${post.parent.relativeDirectory}`}
								target={'_blank'}
								rel="noreferrer"
							>
								<li>
									<div className="flex column-to-row">
										<img
											src={post.frontmatter.productImages[0]}
											width={"281px"}
											height={"211px"}
											alt={post.frontmatter.name}
										/>
										<div className="txt-box">
											<p className="sku">{post.frontmatter.sku}</p>
											<p className="name medium bold">{post.frontmatter.name}</p>
											<p className="name medium bold">{post.frontmatter.model}</p>
											<p className="price medium bold">
												{post.frontmatter.price}
												{yen}
												<span>({taxIncluded} {post.frontmatter.taxIncludedPrice}{yen})</span>											</p>
										</div>
									</div>
								</li>
							</a>
						))}
					</ul>
			}
		</div>
	);
};

export default CatalogProductList;
